import { graphql } from "gatsby";
import React from "react";
import BlockZone from "~blockZone";
import Layout from "../components/layout";
import { PageMeta } from "../components/pageMeta";

const PageTemplate = ({ data: { page } }) => {
  const { meta, pageBlocks, headerReversed } = page || {};
  return (
    <Layout headerReversed={headerReversed}>
      <PageMeta {...meta} />
      <div>{pageBlocks && <BlockZone {...pageBlocks} />}</div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      bg
      headerReversed
    }
  }
`;
